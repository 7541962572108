<template>
  <div>
    <fingerprint-reader>

    </fingerprint-reader>
  </div>
</template>


<script>
import FingerprintReader from '@/components/FingerprintReader.vue'

export default {
  components: {
    FingerprintReader
  },
  data: () => ({
  }),
  methods: {
  }
}
</script>