<template>
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="pa-0 ma-0">
        <v-tabs
          v-model="tab"
          centered
          grow
        >
          <v-tabs-slider></v-tabs-slider>
          <!--
          <v-tab
             href="#solicitudes"
            @click="actualizarItems('solicitudes')"
          >
            Solicitudes de pago
          </v-tab>
          -->

          <!--
          <v-tab
            v-if="mostrarVista(15)"
             href="#altovalor"
            @click="actualizarItems('altovalor')"
          >
            <v-badge
              v-if="num_pagos_alto_valor > 0"
              left
              inline
              overlap
              color="red"
              :content="num_pagos_alto_valor"
            >
            </v-badge>
            Autorización de Pagos de Alto Monto
          </v-tab>
          -->

          <v-tab
             href="#proveedores"
            @click="actualizarItems('proveedores')"
          >
            Proveedores
          </v-tab>

          <v-tab
             href="#proveedoresvalidar"
            @click="actualizarItems('proveedoresvalidar')"
          >
            Proveedores<br>Registro Completo
          </v-tab>

          <!--
          <v-tab
             href="#tipos"
            @click="actualizarItems('tipos')"
          >
            Tipo de Proveedores
          </v-tab>
          -->

          <!--
          <v-tab
             href="#metodos"
            @click="actualizarItems('metodos')"
          >
            Métodos de Pago
          </v-tab>
          -->
        </v-tabs>
          
        <v-tabs-items v-model="tab">
          <!--
          <v-tab-item value="solicitudes">
            <Solicitudesdepago
              ref="solicitudes"
              @change="fetchPagosAltoValor"
            ></Solicitudesdepago>
          </v-tab-item>

          <v-tab-item
            v-if="mostrarVista(15)"
            value="altovalor"
          >
            <AltoValor
              ref="altovalor"
              @change="fetchPagosAltoValor"
            ></AltoValor>
          </v-tab-item>
          -->

          <v-tab-item value="proveedores">
            <Proveedores
              ref="proveedores"
            ></Proveedores>
          </v-tab-item>

          <v-tab-item value="proveedoresvalidar">
            <ProveedoresValidar
              ref="proveedoresvalidar"
            ></ProveedoresValidar>
          </v-tab-item>

          <!--
          <v-tab-item value="tipos">
            <TipoProveedores
              ref="tipos"
            ></TipoProveedores>
          </v-tab-item>
          -->

          <!--
          <v-tab-item value="metodos">
            <MetodoPagos
              ref="metodos"
            ></MetodoPagos>
          </v-tab-item>
          -->

        </v-tabs-items>
      </v-col>
    </v-row>
</template>

<script>
//import Solicitudesdepago from './Pagos/Solicitudesdepago.vue'
import Proveedores from './Pagos/Proveedores.vue'
//import TipoProveedores from './Pagos/TipoProveedores.vue'
//import MetodoPagos from './Pagos/MetodoPagos.vue'
//import AltoValor from './Pagos/AltoValor.vue'
import ProveedoresValidar from './Pagos/ProveedoresValidar.vue'
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['ids_vistas'])
  },
  components:{
    //Solicitudesdepago,
    Proveedores,
    //TipoProveedores,
    //MetodoPagos,
    //AltoValor,
    ProveedoresValidar
  },
  data: () => ({
    tab: null,
    interval: null,
    num_pagos_alto_valor: 0,
  }),
  beforeDestroy(){
    if(this.interval == null) return;
    clearInterval(this.interval);
    this.interval = null;
  },
  mounted() {
    this.obtenerPagosAltoValor();
  },
  methods: {
    mostrarVista(id_vista){
			if(this.ids_vistas == null || this.ids_vistas.length == 0) return false;
			return (this.ids_vistas.indexOf(id_vista) >= 0);
		},
    actualizarItems(refName) {
      if (this.$refs[refName] && this.$refs[refName].getItems) {
        this.$refs[refName].getItems();
      } else {
        console.warn(`No se encontró la referencia: ${refName}`);
      }
    },
    obtenerPagosAltoValor(){
      if(!this.mostrarVista(15)) return;
      this.fetchPagosAltoValor();

      this.interval = setInterval(() => {
        this.fetchPagosAltoValor();
      }, 60000);
    },
    fetchPagosAltoValor(){
      axios
      .get('/Pagos/ContarPagosAltosMontos')
      .then(response => {
        this.num_pagos_alto_valor = response.data;
      })
      .catch(error => {
        console.log(error)
      })
    }
  }
}
</script>