<template>
  <iframe
    ref="fingerFrame"
    src="/finger-reader/index.html"
    width="600"
    height="400"
    style="border: none;"
  ></iframe>
</template>
<script>
export default {
  name: 'TuComponentePadre',

  mounted() {
    window.addEventListener('message', this.onMessageFromIframe);
  },

  beforeUnmount() {
    window.removeEventListener('message', this.onMessageFromIframe);
  },

  methods: {
    onMessageFromIframe(event) {
      // Opcional: validar origin con event.origin
      // if (event.origin !== 'https://tudominio.com') return;

      const { data } = event; // data es el objeto que envía postMessage
      if (data && data.type === 'FINGERPRINT_READ') {
        console.log('Datos de huella recibidos:', data.data);
        // Haz lo que necesites con la huella
      }
    }
  }
}
</script>